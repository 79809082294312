import { default as _91form_93CuzAGlHcqMMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedVReZAM3BC2Meta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishtREuQxtaScMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactionPVPTovQ3b9Meta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startbqM8EMJfxzMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93EDIkvk1bBaMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexXbxM6UBo7bMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginzguxEHThdHMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectWVcKkYec2IMeta } from "/codebuild/output/src1855023989/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93EDIkvk1bBaMeta?.name ?? "research",
    path: _91_91research_93_93EDIkvk1bBaMeta?.path ?? "/:research?",
    meta: _91_91research_93_93EDIkvk1bBaMeta || {},
    alias: _91_91research_93_93EDIkvk1bBaMeta?.alias || [],
    redirect: _91_91research_93_93EDIkvk1bBaMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93CuzAGlHcqMMeta?.name ?? "research-form",
    path: _91form_93CuzAGlHcqMMeta?.path ?? ":form()",
    meta: _91form_93CuzAGlHcqMMeta || {},
    alias: _91form_93CuzAGlHcqMMeta?.alias || [],
    redirect: _91form_93CuzAGlHcqMMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedVReZAM3BC2Meta?.name ?? "research-denied",
    path: deniedVReZAM3BC2Meta?.path ?? "denied",
    meta: deniedVReZAM3BC2Meta || {},
    alias: deniedVReZAM3BC2Meta?.alias || [],
    redirect: deniedVReZAM3BC2Meta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishtREuQxtaScMeta?.name ?? "research-finish",
    path: finishtREuQxtaScMeta?.path ?? "finish",
    meta: finishtREuQxtaScMeta || {},
    alias: finishtREuQxtaScMeta?.alias || [],
    redirect: finishtREuQxtaScMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interactionPVPTovQ3b9Meta?.name ?? "research-interaction",
    path: interactionPVPTovQ3b9Meta?.path ?? "interaction",
    meta: interactionPVPTovQ3b9Meta || {},
    alias: interactionPVPTovQ3b9Meta?.alias || [],
    redirect: interactionPVPTovQ3b9Meta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: startbqM8EMJfxzMeta?.name ?? "research-start",
    path: startbqM8EMJfxzMeta?.path ?? "start",
    meta: startbqM8EMJfxzMeta || {},
    alias: startbqM8EMJfxzMeta?.alias || [],
    redirect: startbqM8EMJfxzMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexXbxM6UBo7bMeta?.name ?? "index",
    path: indexXbxM6UBo7bMeta?.path ?? "/",
    meta: indexXbxM6UBo7bMeta || {},
    alias: indexXbxM6UBo7bMeta?.alias || [],
    redirect: indexXbxM6UBo7bMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginzguxEHThdHMeta?.name ?? "login",
    path: loginzguxEHThdHMeta?.path ?? "/login",
    meta: loginzguxEHThdHMeta || {},
    alias: loginzguxEHThdHMeta?.alias || [],
    redirect: loginzguxEHThdHMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectWVcKkYec2IMeta?.name ?? "redirect",
    path: redirectWVcKkYec2IMeta?.path ?? "/redirect",
    meta: redirectWVcKkYec2IMeta || {},
    alias: redirectWVcKkYec2IMeta?.alias || [],
    redirect: redirectWVcKkYec2IMeta?.redirect,
    component: () => import("/codebuild/output/src1855023989/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]